import {ColumnProps,EnumProps,EnumColumnUse} from '@/components'
import {RList,RListData,getLabelManage,addLabelManage,deleteLabelManage,updateLabelManage,selectTreeApi,IMYLabelSearch,IMYLabel,getIsAliveEnum,
    getBindingStatusEnum,getOrgEnum,getLabelEnum,getOrgLocationEnum} from '@/ts'

const config:RList<IMYLabel, IMYLabelSearch>={
    title: "标签",
    // 列表数据接口
    requestApi:async (param:IMYLabelSearch):Promise<RListData<IMYLabel>>=>{
        const data=await getLabelManage(param);
        return {
            list:data.list,
            pageNum:data.current,
            pageSize:data.pageSize,
            total:data.total
        }; 
    },
    // 新增接口
    addApi:async(row:IMYLabel):Promise<boolean>=>{
        const data=await addLabelManage(row)
        console.log('新增接口');
        return data;
    },
    // 修改接口
    editApi:async(row:IMYLabel):Promise<boolean>=>{
        const data=await updateLabelManage(row)
        console.log('修改接口');
        return data;
    },
     //当前位置树接口
    treeApi:async():Promise<any>=>{
        const data=await selectTreeApi()
        console.log('修改接口');
        return data;
    },
    // 多行删除接口
    /* deleteApi:async(param:{id:string[]}):Promise<boolean>=>{
        const data=await deleteLabelManage(param)
        console.log(data,'data');
        console.log('删除接口');
        return data;
    }, */
    // 多行删除接口
    deleteApi:async(ids:string[]):Promise<boolean>=>{
        const data=await deleteLabelManage(ids)
        console.log('删除接口');
        return data;
    },
    selectId:"deviceId",
    // 表格列信息
    columns:[
        { prop:"deviceId", type: "selection", fixed: "left", width: 55 , use:EnumColumnUse.LIST},
        // { type: "index", label: "#", width: 80 , use:EnumColumnUse.LIST},
        { type: "expand", label: "Expand", width: 100 , use:EnumColumnUse.LIST},
    
        {prop:"deviceId", label:"标签ID", width:"250", el: "input",
            search: { key:"key", label:"关键字搜索", props:{placeholder:"标签ID、MAC地址、当前位置"} }, 
            edit: {props:{disabled:true}},
            use:EnumColumnUse.LIST|EnumColumnUse.SEARCH|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"type", label:"标签类型", width:"90", el: "select",
            search: {},
            enum:getBindingStatusEnum(),
            edit: {props:{disabled:true}},
            use:EnumColumnUse.LIST|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"labelStatus", label:"标签状态", width:"90", el: "select" , 
            search: { key:"isOnline", props: { filterable: true }}, 
            enum:getIsAliveEnum(), use:EnumColumnUse.LIST|EnumColumnUse.SEARCH},
        {prop:"bindingStatus", label:"绑定状态", width:"90", el: "select" , 
            search: { key:"isBinding", props: { filterable: true }}, 
            enum:getBindingStatusEnum(), use:EnumColumnUse.LIST|EnumColumnUse.SEARCH},
        {prop:"assetName", label:"标签对应资产", width:"150", use:EnumColumnUse.LIST},
        {prop:"", label:"标签电量", width:"150", use:EnumColumnUse.LIST},
        {prop:"mac", label:"MAC地址", width:"210", use:EnumColumnUse.LIST},
        {prop:"removeStatus", label:"防拆标记", width:"110", use:EnumColumnUse.LIST},
        {prop:"org", label:"所属单位", width:"180", el:"select",
            enum:getOrgEnum(), use:EnumColumnUse.LIST|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"area", label:"当前位置", width:"210", el:"cascader",enum:getOrgLocationEnum(),
            use:EnumColumnUse.LIST|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"initArea", label:"安全位置", width:"210", el:"input",
            use:EnumColumnUse.LIST|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"voltage", label:"电压", width:"80", use:EnumColumnUse.LIST},
        {prop:"temperature", label:"温度", width:"80", use:EnumColumnUse.LIST},
        { prop: "operation", label: "操作", fixed: "right", width: 220 , use:EnumColumnUse.LIST}
    ],
    rules:{
        deviceId:[
            {required: true, message: '这是必填项', trigger: 'blur'},
            {message:'标签ID格式有误！', pattern:/^BA01[a-zA-Z0-9]{4}[2-9][1-9A-C][0-3][0-9][a-fA-F0-9]{12}$|^FD0[1-5][a-zA-Z0-9]{8}[2-9][1-9A-C][0-3][0-9][a-zA-Z0-9]{8}$|^FA01[a-zA-Z0-9]{20}$|^DC0[1-2][a-zA-Z0-9]{20}$/}
        ],
        type:[
            {required: true, message: '这是必填项', trigger: 'blur'}
        ],
        org:[
            {required: true, message: '这是必填项', trigger: 'blur'},
        ],
        area:[
            {required: true, message: '这是必填项', trigger: 'blur'},
        ],
        initArea:[
            {required: true, message: '这是必填项', trigger: 'blur'},
        ]
    }
};

export default config;